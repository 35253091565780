import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-navlist', 'MY_SHORTCUTS', 'Mano nuorodos');
Labels.set('ui-navlist', 'REMOVE_SHORTCUT', 'Pašalinti');
Labels.set(
    'ui-navlist',
    'ADD_SHORTCUT',
    'Įtraukti šį puslapį į nuorodų sąrašą'
);
Labels.set('ui-navlist', 'EDIT_SHORTCUTS', 'Tvarkyti mano nuorodas');
Labels.set('ui-navlist', 'FINISH_EDITING', 'Išsaugoti pakeitimus');
Labels.set('ui-navlist', 'BACK', 'Atgal');
