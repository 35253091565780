import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-stepper', {
    month: {
        0: 'mėnesių',
        1: 'mėnuo',
        2: 'mėnesiai',
    },
    year: {
        0: 'metų',
        1: 'metai',
        2: 'metai',
    },
    increase: 'Padidinti',
    decrease: 'Sumažinti',
    value: 'Vertė',
});
