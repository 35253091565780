/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-cookie-consent', 'title', 'Mūsų slapukai – Jūsų pasirinkimai');
Labels.set(
    'ui-cookie-consent',
    'info',
    '„Swedbank“ grupės {entitiesLink}, veikiančios {country}, naudoja {cookiesLink} (ar panašias technologijas) tam, kad suteiktų Jums pritaikytą naršymo patirtį ir pagerintų interneto svetainės veikimą. Daugiau informacijos rasite mūsų {policyLink}.\n\nSpustelėdami „Sutinku“ sutinkate su analitiniais ir rinkodaros slapukais, o spustelėdami „Nesutinku“ jų atsisakote. Pasirenkamuosius slapukus taip pat galite valdyti žemiau. Savo sutikimą bet kada galite atšaukti mūsų {usageLink}. '
);
Labels.set(
    'ui-cookie-consent',
    'disclaimer',
    'Kai kurie slapukai yra būtini šios svetainės veikimui ir jų naudojimas grindžiamas  mūsų teisėtu interesu, todėl Jūsų sutikimo neprašoma. Šioje svetainėje naudojami trečiųjų šalių slapukai.'
);
Labels.set('ui-cookie-consent', 'accept', 'Sutinku');
Labels.set('ui-cookie-consent', 'decline', 'Nesutinku');
Labels.set('ui-cookie-consent', 'saveChoice', 'Išsaugoti pasirinkimą');
Labels.set('ui-cookie-consent', 'myCookies', 'Pasirinkti savo slapukus');
Labels.set(
    'ui-cookie-consent',
    'policyLinkLabel',
    '„Swedbank“ slapukų politikoje'
);
Labels.set('ui-cookie-consent', {
    HELP_NECESSARY:
        'Yra būtini tam, kad svetainė veiktų ir negali būti išjungti, todėl jiems neprašomas sutikimas.',
    HELP_ANALYTICAL:
        'Padeda tobulinti svetainės veikimą, nes suteikia galimybę fiksuoti apsilankymų skaičių bei lankytojų srauto šaltinius. Nesutikus leisti įrašyti šių slapukų, Jūsų apsilankymas svetainėje nepateks į jos statistiką.',
    HELP_TARGETING:
        'Padeda šioje svetainėje pateikti Jums pritaikytą  informaciją apie paslaugas, pagerinti turinio atranką, tobulinti svetainės funkcijas. Gali būti naudojami rinkodaros tikslu. Nesutikus priimti šių slapukų, nebus rodomas Jums pritaikytas turinys.',
});
Labels.set('ui-cookie-consent', {
    CATEGORY_NECESSARY: 'Būtinieji',
    CATEGORY_ANALYTICAL: 'Analitiniai',
    CATEGORY_TARGETING: 'Rinkodaros',
});
Labels.set('ui-cookie-consent', 'EE', 'Estija');
Labels.set('ui-cookie-consent', 'LV', 'Latvija');
Labels.set('ui-cookie-consent', 'LT', 'Lietuvoje');
Labels.set('ui-cookie-consent', 'entities', 'įmonės');
Labels.set('ui-cookie-consent', 'cookies', 'slapukus');
Labels.set('ui-cookie-consent', 'language', 'Kalba');
Labels.set('ui-cookie-consent', 'usage', 'slapukų naudojimo puslapyje');
/* eslint-enable max-len */
