/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set(
    'ui-tabs',
    'arrowKeys',
    'Rodyklių klavišais galima naršyti tarp skirtukų'
);
Labels.set('ui-tabs', 'selectTab', 'Pasirinkite skirtuką');
/* eslint-enable max-len */
