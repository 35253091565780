import { Grammar } from './grammar.js';

if (!window['UI-LABELS-STORAGE']) {
    window['UI-LABELS-STORAGE'] = {};
}
const labelsRegistry = window['UI-LABELS-STORAGE'];

const Labels = Object.seal({
    LOCALE: 'en',
    LANGUAGE: 'en',

    /**
     * @param {string} target
     * @returns {Record<string, string>}
     */
    getAll(target) {
        if (!labelsRegistry[target]) {
            labelsRegistry[target] = {};
        }
        return labelsRegistry[target];
    },

    /**
     * @param {string} target
     * @param {UILabelType} [defaultLabels]
     * @returns {UILabelType}
     */
    attach(target, defaultLabels) {
        const data = this.getAll(target);
        if (defaultLabels) {
            Object.keys(defaultLabels).forEach((key) => {
                if (!data.hasOwnProperty(key)) {
                    data[key] = defaultLabels[key];
                }
            });
        }
        return data;
    },

    /**
     * @param {string} target
     * @param {string | object} obj
     * @param {string} [label]
     */
    set(target, obj, label) {
        const data = this.getAll(target);
        if (typeof obj === 'string') {
            data[obj] = label;
        } else if (typeof obj === 'object') {
            Object.entries(obj).forEach(([key, value]) => {
                data[key] = value;
            });
        }
    },

    /**
     * Imports labels from third-party storage
     * @param {string} target
     * @param {Function} resolve
     * @param {Array<string>} keys
     */
    import(target, resolve, keys) {
        keys.forEach((key) => {
            const res = resolve(key);
            if (res !== key && typeof res !== 'undefined') {
                this.set(target, key, res);
            }
        });
    },

    processLabel(labelStorage, key, substitutions) {
        const type = typeof substitutions;
        let quantityDefined;
        if (!labelStorage) {
            return key;
        }
        const label = labelStorage.hasOwnProperty(key)
            ? labelStorage[key]
            : key;

        switch (type) {
            case 'number':
                quantityDefined = substitutions !== undefined;
                if (quantityDefined) {
                    const quantifiedKey =
                        key + '.' + Grammar.quantify(substitutions);
                    const rawLabel = labelStorage[quantifiedKey];
                    if (rawLabel) {
                        return rawLabel.replace(/%q/g, substitutions);
                    }
                    const singleLabel = labelStorage[key];
                    return singleLabel
                        ? singleLabel.replace(/%q/g, substitutions)
                        : quantifiedKey;
                }
                return label;
            case 'object':
                if (Array.isArray(substitutions)) {
                    return substitutions.reduce((str, entry, index) => {
                        return str.replace('%' + (index + 1), entry);
                    }, label);
                } else {
                    return Object.entries(substitutions || {}).reduce(
                        (str, [key, value]) => str.replace(`{${key}}`, value),
                        label
                    );
                }
            default:
                return label;
        }
    },
});

export { Labels, labelsRegistry };

/**
 * @typedef {
 *    Record<string, string | Array<string> | Record<string|number, string|Array<string>>>
 * } UILabelType
 */
