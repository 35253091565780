/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-assets', 'labelMore', 'Rodyti daugiau');
Labels.set('ui-assets', 'labelLess', 'Rodyti mažiau');
Labels.set(
    'ui-assets',
    'labelToggleContent',
    'Šiuo metu {visibleAssets} iš {allAssets} sąrašo elementų matoma.'
);
/* eslint-enable max-len */
