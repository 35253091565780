/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-calendar', 'weekDays', [
    'Pir',
    'Ant',
    'Tre',
    'Ket',
    'Pen',
    'Šeš',
    'Sek',
]);
Labels.set('ui-calendar', 'weekDaysLong', [
    'Pirmadienis',
    'Antradienis',
    'Trečiadienis',
    'Ketvirtadienis',
    'Penktadienis',
    'Šeštadienis',
    'Sekmadienis',
]);
Labels.set('ui-calendar', 'prevMonth', 'Praeita mėnesį');
Labels.set('ui-calendar', 'nextMonth', 'Kitą mėnesį');
Labels.set(
    'ui-calendar',
    'arrowKeys',
    'Rodyklių klavišais galima naršyti datas'
);
/* eslint-enable max-len */
