/* eslint-disable max-len */

import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-feedback', 'MIN_RATE', 'Buvo sudėtinga');
Labels.set('ui-feedback', 'MAX_RATE', 'Buvo lengva');
Labels.set('ui-feedback', 'POSITIVE', 'Taip');
Labels.set('ui-feedback', 'NEGATIVE', 'Ne');
Labels.set('ui-feedback', 'CONFIRM', 'Siųsti');
Labels.set(
    'ui-feedback',
    'TEXTAREA_LABEL',
    'Pasidalinkite, ką galėtume patobulinti, kad ateityje Jūsų vertinimas būtų geresnis?'
);
Labels.set(
    'ui-feedback',
    'HINT_MESSAGE',
    'Jūsų atsiliepimas yra anoniminis. Jis mums padės tobulinti paslaugų teikimą.'
);
Labels.set('ui-feedback', 'SUCCESS_MESSAGE', 'Dėkojame už Jūsų atsiliepimą!');
Labels.set('ui-feedback', 'ERROR_MESSAGE', 'Feedback sending failed');
