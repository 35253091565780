import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-monthpicker', 'months', [
    'Sausis',
    'Vasaris',
    'Kovas',
    'Balandis',
    'Gegužė',
    'Birželis',
    'Liepa',
    'Rugpjūtis',
    'Rugsėjis',
    'Spalis',
    'Lapkritis',
    'Gruodis',
]);
Labels.set('ui-monthpicker', 'year', 'Metai');
Labels.set('ui-monthpicker', 'month', 'Mėnuo');
