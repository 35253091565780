/**
 * @memberof SharedComponents
 * @alias Grammar
 */
export class Grammar {
    /**
     * @type {{SINGULAR: number, FIVE_AND_ABOVE_PLURAL: number, ZERO_TEN: number, PLURAL: number}}
     */
    static get quantity() {
        return {
            ZERO_TEN: 0,
            SINGULAR: 1,
            PLURAL: 2,
            FIVE_AND_ABOVE_PLURAL: 5,
        };
    }

    /**
     * @param {number} quantity
     * @param {string} [language]
     * @returns {number}
     */
    static quantify(quantity, language = '') {
        const remainder = quantity % 10;

        if (!language) {
            // Temporary fallback for backwards compatibility
            language =
                window['UI'] &&
                window['UI']['Labels'] &&
                window['UI']['Labels'].LANGUAGE;
        }

        switch (language || 'en') {
            case 'ru':
                if (remainder !== 0 && (quantity < 10 || quantity > 20)) {
                    if (remainder === 1) {
                        return Grammar.quantity.SINGULAR;
                    } else {
                        return remainder !== 2 &&
                            remainder !== 3 &&
                            remainder !== 4
                            ? Grammar.quantity.FIVE_AND_ABOVE_PLURAL
                            : Grammar.quantity.PLURAL;
                    }
                } else {
                    return Grammar.quantity.FIVE_AND_ABOVE_PLURAL;
                }
            case 'lt':
                if (remainder !== 0 && (quantity < 10 || quantity > 20)) {
                    return remainder === 1
                        ? Grammar.quantity.SINGULAR
                        : Grammar.quantity.PLURAL;
                } else {
                    return Grammar.quantity.ZERO_TEN;
                }
            default:
                return quantity === 1
                    ? Grammar.quantity.SINGULAR
                    : Grammar.quantity.PLURAL;
        }
    }
}
